import { withDependencies } from '@wix/thunderbolt-ioc'
import { SdkHandlersProvider, LoggerSymbol, ILogger } from '@wix/thunderbolt-symbols'
import { SiteMembersWixCodeSdkHandlers, LegacyMemberDTO } from '../types'
import {
	ISiteMembersApi,
	SiteMembersApiSymbol,
	PrivacyStatus,
	INTERACTIONS,
	isLoginAcceptableError,
} from 'feature-site-members'

const SiteMembersWixCodeSdkHandlersFactory = (
	{
		login,
		promptLogin,
		promptForgotPassword,
		applySessionToken,
		getMemberDetails,
		register,
		registerToUserLogin,
		registerToMemberLogout,
		logout,
		handleOauthToken,
		closeCustomAuthenticationDialogs,
		sendSetPasswordEmail,
	}: ISiteMembersApi,
	logger: ILogger
): SdkHandlersProvider<SiteMembersWixCodeSdkHandlers> => {
	return {
		getSdkHandlers() {
			const handlers: SiteMembersWixCodeSdkHandlers = {
				async login(email, password, options) {
					try {
						logger.interactionStarted(INTERACTIONS.CODE_LOGIN)
						const response = await login(email, password, options)
						logger.interactionEnded(INTERACTIONS.CODE_LOGIN)

						// In case someone opened the custom login popup using the popup API
						// we still wish to close the popup on a successful login
						closeCustomAuthenticationDialogs(true)
						return response
					} catch (error) {
						if (isLoginAcceptableError(error)) {
							logger.interactionEnded(INTERACTIONS.CODE_LOGIN)
						}

						throw error
					}
				},
				applySessionToken,
				promptForgotPassword,
				async promptLogin(options) {
					const { member } = await promptLogin(options)
					return member as LegacyMemberDTO // TODO: Maybe consolidate these almost identical types?
				},
				async register(email, password, options) {
					// We wish to allow consumers to manage the captcha by themselves
					const { member, approvalToken, status } = await register(
						email,
						password,
						options?.contactInfo,
						options.privacyStatus || PrivacyStatus.PRIVATE,
						undefined,
						undefined,
						options?.recaptchaToken
					)

					return {
						status,
						approvalToken,
						user: member,
					}
				},
				registerToUserLogin,
				registerToMemberLogout,
				sendSetPasswordEmail,
				logout,
				getMemberDetails,
				handleOauthToken,
			}
			return handlers
		},
	}
}

export const siteMembersWixCodeSdkHandlers = withDependencies(
	[SiteMembersApiSymbol, LoggerSymbol],
	SiteMembersWixCodeSdkHandlersFactory
)
